import './App.css';
import React, { useState, useEffect } from 'react';

function App() {
    const [currentUser, setCurrentUser] = useState({});
    const [data, setData] = useState({});

    function Greeting() {
        if (currentUser.id) {
            return <div><div>Logged in as: {currentUser.name}</div><div>{JSON.stringify(data)}</div></div>;
        }
        return <div>Not logged in</div>;
    }

    const QUERY = `
      {
          currentUser {
            id
            firstName
            lastName
            name
            email
          }
        }
    `;

    useEffect(() => {
        fetch('https://app.hjemmelegene.no/graphql', {
            method: 'post',
            credentials: 'include',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({ query: QUERY })
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data['data']);
                setCurrentUser(data['data']['currentUser']);
                setData(data['data']);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    return (
        <Greeting/>
    );
}

export default App;
